<template>
  <div class="base-list-page">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item label="配餐公司" class="typeInput">
          <el-select v-model="searchForm.orgIds" multiple filterable clearable>
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="客户公司" class="typeInput">
          <el-select
            v-model="searchForm.companyIds"
            multiple
            filterable
            clearable
            @change="changeCompanyIds"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="时段" class="typeInput">
          <el-select
            v-model="searchForm.periodIds"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="智能取餐柜" class="typeInput">
          <el-select
            v-model="searchForm.freezerIds"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in freezerList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="取餐日期" class="typeInput">
          <el-date-picker
            v-model="time"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单编号" class="typeInput">
          <el-input
            v-model="searchForm.orderId"
            placeholder="订单编号"
            size="mini"
            clearable
          />
        </el-form-item>
        <el-form-item label="取餐方式" class="typeInput">
          <el-select v-model="searchForm.pickupWay" filterable clearable>
            <el-option
              v-for="(item, index) in pickupWayList"
              :key="index"
              :label="item.pickupWay"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否团餐" class="typeInput">
          <el-select v-model="searchForm.isGroup" filterable clearable>
            <el-option
              v-for="(item, index) in groupList"
              :key="index"
              :label="item.isGroup"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="用户ID" class="typeInput">
          <el-input
            v-model="searchForm.uuid"
            placeholder="用户ID"
            size="mini"
            clearable
          />
        </el-form-item>
        <el-form-item label="取餐人" class="typeInput">
          <el-input
            v-model="searchForm.consignee"
            placeholder="取餐人"
            size="mini"
            clearable
          />
        </el-form-item>
        <el-form-item label="手机号码" class="typeInput">
          <el-input
            v-model="searchForm.mobile"
            placeholder="手机号码"
            size="mini"
            clearable
          />
        </el-form-item>
        <el-form-item label="订单状态" class="typeInput">
          <el-select v-model="searchForm.state" filterable clearable multiple>
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.state"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="支付类型" class="typeInput">
          <el-select v-model="searchForm.payType" filterable clearable multiple>
            <el-option
              v-for="(item, index) in payTypeList"
              :key="index"
              :label="item.payType"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型" class="typeInput">
          <el-select v-model="searchForm.type" filterable clearable multiple>
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.type"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="订单来源" class="typeInput">
          <el-select v-model="searchForm.source" filterable clearable>
            <el-option
              v-for="(item, index) in sourceList"
              :key="index"
              :label="item.source"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="部门" class="typeInput">
          <el-cascader
            v-model="searchForm.companyAndDepartment"
            :options="options"
            :props="{ expandTrigger: 'hover', multiple: true }"
            @change="changeDepartment"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button
            @click="exportOrder()"
            v-if="isAuth('tc:order:exportOrder')"
            size="small"
            type="primary"
            >导出订单</el-button
          >
          <el-button
            @click="exportCatering()"
            v-if="isAuth('tc:order:exportCatering')"
            size="small"
            type="primary"
            >导出配餐单</el-button
          >
          <el-button
            @click="exportReceipt()"
            v-if="isAuth('tc:order:exportReceipt')"
            size="small"
            type="primary"
            >导出小票</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="listBtn">
      <span>
        订单数量：<b>{{ this.prices.orderCount }}</b>
      </span>
      <span>
        商品数量：<b>{{ this.prices.goodsCount }}</b>
      </span>
      <span>
        商品金额：<b>{{ this.prices.totalPrice }}</b>
      </span>
      <span>
        优惠金额：<b>{{ this.prices.sumSubPrice }}</b>
      </span>
      <span>
        订单金额：<b>{{ this.prices.orderPrice }}</b>
      </span>
    </div>
    <el-table
      :data="dataList"
      border
      stripe
      v-loading="dataListLoading"
      ref="dataList"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        prop="idText"
        header-align="center"
        align="center"
        label="订单编号"
      >
      </el-table-column>
      <el-table-column
        prop="orgName"
        header-align="center"
        align="center"
        label="配餐公司"
      >
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="nickname"-->
      <!--        header-align="center"-->
      <!--        align="center"-->
      <!--        label="微信昵称"-->
      <!--      >-->
      <!--      </el-table-column>-->
      <el-table-column
        prop="uuid"
        header-align="center"
        align="center"
        label="用户ID"
      >
      </el-table-column>
      <el-table-column
        prop="consignee"
        header-align="center"
        align="center"
        label="取餐人"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号码"
      >
      </el-table-column>
      <el-table-column
        prop="isGroup"
        header-align="center"
        align="center"
        label="是否团餐"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isGroup" type="success" size="mini"
            >是</el-tag
          >
          <el-tag v-else-if="!scope.row.isGroup" type="warning" size="mini"
            >否</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="pickupWayText"
        header-align="center"
        align="center"
        label="取餐方式"
      >
      </el-table-column>
      <el-table-column
        prop="periodFullName"
        header-align="center"
        align="center"
        label="时段"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="客户公司"
      >
      </el-table-column>
      <el-table-column
        prop="freezerName"
        header-align="center"
        align="center"
        label="智能取餐柜"
      >
      </el-table-column>
      <el-table-column
        prop="pickupDate"
        header-align="center"
        align="center"
        label="取餐日期"
      >
      </el-table-column>
      <el-table-column
        prop="stateText"
        header-align="center"
        align="center"
        label="状态"
      >
      </el-table-column>
      <el-table-column
        prop="totalPrice"
        header-align="center"
        align="center"
        label="商品金额"
      >
      </el-table-column>
      <el-table-column
        prop="sumSubPrice"
        header-align="center"
        align="center"
        label="优惠金额"
      >
      </el-table-column>
      <el-table-column
        prop="orderPrice"
        header-align="center"
        align="center"
        label="订单金额"
      >
      </el-table-column>
      <el-table-column
        prop="payText"
        header-align="center"
        align="center"
        label="支付方式"
      >
      </el-table-column>
      <el-table-column
        prop="typeText"
        header-align="center"
        align="center"
        label="订单类型"
      >
      </el-table-column>
      <el-table-column
        prop="sourceText"
        header-align="center"
        align="center"
        label="订单来源"
      >
      </el-table-column>
      <el-table-column
        prop="createdAt"
        header-align="center"
        align="center"
        label="下单时间"
      >
      </el-table-column>
      <el-table-column
        prop="paidAt"
        header-align="center"
        align="center"
        label="付款时间"
      >
      </el-table-column>
      <el-table-column
        prop="refundTime"
        header-align="center"
        align="center"
        label="退款时间"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="190"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-if="isAuth('tc:orderDetail:info')"
            @click="$dialog('orderDetail', scope.row)"
          >
            详细
          </el-button>
          <el-button
            type="text"
            size="small"
            v-if="isAuth('tc:orderDetail:info')"
            @click="$dialog('orderRefund', scope.row)"
          >
            退款
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
    <!-- 弹层，仓位管理 -->
    <order-detail
      v-if="orderDetailVisible"
      ref="orderDetail"
      @refreshDataList="getDataList()"
    />
    <order-refund
      v-if="orderRefundVisible"
      ref="orderRefund"
      @refreshDataList="getDataList()"
    />
  </div>
</template>

<script>
import moment from 'moment';
import orderDetail from './order-detail';
import orderRefund from './order-refund';
import { listMixin, normal } from '@/mixins';
import {
  getCompanyList,
  getPeriodList,
  getFreezerList,
  getOrgList,
  getDepartmentList,
} from '@/utils/options.js';
export default {
  mixins: [listMixin, normal],
  data() {
    return {
      orderDetailVisible: false,
      time: [],
      searchForm: {
        name: '',
        pickupWay: '',
        companyIds: [],
        companyAndDepartment: [],
        departmentIds: [],
        orgIds: [],
        freezerIds: [],
        periodIds: [],
        companyName: '',
        orgName: '',
        periodName: '',
        freezerName: '',
        departmentName: '',
        orderId: '',
        uuid: '',
        nickname: '',
        registerMobile: '',
        pickupDateStart: '',
        pickupDateEnd: '',
        consignee: '',
        mobile: '',
        state: [],
        type: [],
        payType: [],
        isGroup: '',
        source: '',
      },
      prices: {
        totalPrice: 0,
        orderPrice: 0,
        sumSubPrice: 0,
        orderCount: 0,
        goodsCount: 0,
      },
      dataList: [],
      companyList: [],
      freezerList: [],
      periodList: [],
      orgList: [],
      stateList: [
        { id: 0, state: '待付款' },
        { id: 1, state: '已付款' },
        { id: 5, state: '待配餐/制作中' },
        { id: 6, state: '待取餐' },
        { id: 129, state: '已完成' },
        { id: 128, state: '已过期' },
        { id: 130, state: '已取消' },
        { id: 131, state: '已退款' },
      ],
      pickupWayList: [
        { id: 1, pickupWay: '取餐柜取' },
        { id: 2, pickupWay: '堂食' },
        { id: 3, pickupWay: '外带' },
      ],
      typeList: [
        { id: 0, type: '现售' },
        { id: 1, type: '自由预售' },
        { id: 2, type: '秒杀' },
        { id: 3, type: '限定预售' },
        { id: 4, type: '堂食现售' },
      ],
      payTypeList: [
        { id: 1, payType: '微信支付' },
        { id: 2, payType: '余额支付' },
        { id: 3, payType: '补助支付' },
        { id: 4, payType: '微信补助组合支付' },
        { id: 5, payType: '余额补助组合支付' },
        { id: 6, payType: '支付宝支付' },
        { id: 7, payType: '银联支付' },
        { id: 8, payType: '数字人民币支付' },
        { id: 9, payType: '火炬园一卡通支付' },
      ],
      sourceList: [
        { id: 1, source: '小程序' },
        { id: 2, source: '点餐机' },
      ],
      groupList: [
        { id: 0, isGroup: '否' },
        { id: 1, isGroup: '是' },
      ],
      options: [],
      initialOptions: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      addOrUpdateVisible: false,
      orderRefundVisible: false,
    };
  },
  components: {
    orderRefund,
    orderDetail,
  },
  activated() {
    this.getDataList(1);
  },
  created() {
    this.getCompanyList();
    this.getOrgList();
    this.getFreezerList();
    this.getPeriodList();
    this.getDepartmentList();
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      if (this.time?.length) {
        this.searchForm.pickupDateStart = this.time[0];
        this.searchForm.pickupDateEnd = this.time[1];
      } else {
        this.searchForm.pickupDateStart = '';
        this.searchForm.pickupDateEnd = '';
      }
      this.$http({
        url: '/tc/order/list',
        method: 'get',
        params: {
          page: page ? page : this.pageIndex,
          perPage: this.pageSize,
          ...this.searchForm,
          state: this.searchForm.state.join(','),
          type: this.searchForm.type.join(','),
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataList = data.data.items;
          this.totalPage = data.data.total;
          this.prices = data.prices;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    getFreezerList() {
      getFreezerList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.freezerList = data.data.items;
        }
      });
    },
    getPeriodList() {
      getPeriodList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
    getDepartmentList() {
      getDepartmentList().then(({ data }) => {
        if (data && data.code === 0) {
          this.options = data.option.options;
          this.initialOptions = data.option.options;
        }
      });
    },
    changeCompanyIds() {
      if (this.searchForm.companyIds.length > 0) {
        this.options = this.initialOptions.filter((item) =>
          this.searchForm.companyIds.includes(item.value),
        );
      } else {
        this.options = this.initialOptions;
      }
      if (this.searchForm.companyAndDepartment.length > 0) {
        this.searchForm.companyAndDepartment =
          this.searchForm.companyAndDepartment.filter((item) =>
            this.searchForm.companyIds.includes(item[0]),
          );
      }
      this.changeDepartment();
    },
    changeDepartment() {
      this.searchForm.departmentIds = this.searchForm.companyAndDepartment.map(
        (item) => item[1],
      );
      let department = [];
      this.options.forEach((item) =>
        item.children.forEach((sitem) => {
          if (this.searchForm.departmentIds.includes(sitem.value))
            department.push(item.label + '/' + sitem.label);
        }),
      );
      this.searchForm.departmentName = department.join(',');
    },

    exportOrder() {
      if (this.time?.length) {
        this.searchForm.pickupDateStart = this.time[0];
        this.searchForm.pickupDateEnd = this.time[1];
      } else {
        this.searchForm.pickupDateStart = '';
        this.searchForm.pickupDateEnd = '';
      }
      this.searchForm.companyName = this.companyList
        .filter((item) => this.searchForm.companyIds.includes(item.id))
        .map((item) => item.name)
        .join(',');
      this.searchForm.orgName = this.orgList
        .filter((item) => this.searchForm.orgIds.includes(item.id))
        .map((item) => item.name)
        .join(',');
      this.searchForm.periodName = this.periodList
        .filter((item) => this.searchForm.periodIds.includes(item.id))
        .map((item) => item.withTimeName)
        .join(',');
      this.searchForm.freezerName = this.freezerList
        .filter((item) => this.searchForm.freezerIds.includes(item.id))
        .map((item) => item.name)
        .join(',');
      this.searchForm.departmentIds;
      this.$http({
        url: `/tc/order/exportOrderNew`,
        method: 'post',
        params: {
          ...this.searchForm,
          state: this.searchForm.state.join(','),
          type: this.searchForm.type.join(','),
        },
        responseType: 'blob',
      }).then(({ data }) => {
        if (data && data.size > 0) {
          const content = data;
          const blob = new Blob([content], {
            // 下载的文件格式自己在这边更改type的值就好了
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download =
              '订单_-_' + moment(new Date()).format('YYYYMMDDHHmmss');
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            navigator.msSaveBlob(
              blob,
              '订单_-_' + moment(new Date()).format('YYYYMMDDHHmmss'),
            );
          }
        }
      });
    },

    exportCatering() {
      if (this.time?.length) {
        this.searchForm.pickupDateStart = this.time[0];
        this.searchForm.pickupDateEnd = this.time[1];
      } else {
        this.searchForm.pickupDateStart = '';
        this.searchForm.pickupDateEnd = '';
      }
      this.$http({
        url: `/tc/order/exportCatering`,
        method: 'post',
        params: {
          ...this.searchForm,
          state: this.searchForm.state.join(','),
          type: this.searchForm.type.join(','),
        },
        responseType: 'blob',
      }).then(({ data }) => {
        if (data && data.size > 0) {
          const content = data;
          const blob = new Blob([content], {
            // 下载的文件格式自己在这边更改type的值就好了
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download =
              '配餐单_-_' + moment(new Date()).format('YYYYMMDDHHmmss');
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            navigator.msSaveBlob(
              blob,
              '配餐单_-_' + moment(new Date()).format('YYYYMMDDHHmmss'),
            );
          }
        }
        console.log(data);
      });
      this.exportCateringStatistics();
    },
    exportCateringStatistics() {
      if (this.time?.length) {
        this.searchForm.pickupDateStart = this.time[0];
        this.searchForm.pickupDateEnd = this.time[1];
      } else {
        this.searchForm.pickupDateStart = '';
        this.searchForm.pickupDateEnd = '';
      }
      this.$http({
        url: `/tc/order/exportCateringStatistics`,
        method: 'post',
        params: {
          ...this.searchForm,
          state: this.searchForm.state.join(','),
          type: this.searchForm.type.join(','),
        },
        responseType: 'blob',
      }).then(({ data }) => {
        if (data && data.size > 0) {
          const content = data;
          const blob = new Blob([content], {
            // 下载的文件格式自己在这边更改type的值就好了
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download =
              '配餐统计_-_' + moment(new Date()).format('YYYYMMDDHHmmss');
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            navigator.msSaveBlob(
              blob,
              '配餐统计_-_' + moment(new Date()).format('YYYYMMDDHHmmss'),
            );
          }
        }
        console.log(data);
      });
    },

    exportReceipt() {
      if (this.time?.length) {
        this.searchForm.pickupDateStart = this.time[0];
        this.searchForm.pickupDateEnd = this.time[1];
      } else {
        this.searchForm.pickupDateStart = '';
        this.searchForm.pickupDateEnd = '';
      }
      this.$http({
        url: `/tc/order/exportReceipt`,
        method: 'post',
        params: {
          ...this.searchForm,
          state: this.searchForm.state.join(','),
          type: this.searchForm.type.join(','),
        },
        responseType: 'blob',
      }).then(({ data }) => {
        if (data && data.size > 0) {
          const content = data;
          const blob = new Blob([content], {
            // 下载的文件格式自己在这边更改type的值就好了
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download =
              '小票_-_' + moment(new Date()).format('YYYYMMDDHHmmss');
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            navigator.msSaveBlob(
              blob,
              '小票_-_' + moment(new Date()).format('YYYYMMDDHHmmss'),
            );
          }
        }
        console.log(data);
      });
    },

    exportFinance() {
      if (this.time?.length) {
        this.searchForm.pickupDateStart = this.time[0];
        this.searchForm.pickupDateEnd = this.time[1];
      } else {
        this.searchForm.pickupDateStart = '';
        this.searchForm.pickupDateEnd = '';
      }
      const period = this.searchForm.periodId;
      const company = this.searchForm.companyId;
      const freezer = this.searchForm.freezerId;
      const org = this.searchForm.orgId;
      if (period != '') {
        this.searchForm.periodName = this.periodList.filter(
          (i) => i.id === period,
        )[0].name;
      }
      if (company != '') {
        this.searchForm.companyName = this.companyList.filter(
          (i) => i.id === company,
        )[0].name;
      }
      if (freezer != '') {
        this.searchForm.freezerName = this.freezerList.filter(
          (i) => i.id === freezer,
        )[0].name;
      }
      if (org != '') {
        this.searchForm.orgName = this.orgList.filter(
          (i) => i.id === org,
        )[0].name;
      }
      this.$http({
        url: `/tc/order/exportFinance`,
        method: 'post',
        params: {
          ...this.searchForm,
          state: this.searchForm.state.join(','),
          type: this.searchForm.type.join(','),
        },
        responseType: 'blob',
      }).then(({ data }) => {
        if (data && data.size > 0) {
          const content = data;
          const blob = new Blob([content], {
            // 下载的文件格式自己在这边更改type的值就好了
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download =
              '财务报表_-_' + moment(new Date()).format('YYYYMMDDHHmmss');
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            navigator.msSaveBlob(
              blob,
              '财务报表_-_' + moment(new Date()).format('YYYYMMDDHHmmss'),
            );
          }
        }
      });
    },
  },
};
</script>
